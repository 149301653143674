<script setup>
    import { provide } from 'vue';

    import { TweakPane } from '@resn/gozer-vue';

    provide('sandbox', true);
</script>

<template>
    <TweakPane>
        <div class="layout-sandbox">
            <slot />
        </div>
    </TweakPane>
</template>
